// extracted by mini-css-extract-plugin
export var ArtistDescription = "Delta2-module--ArtistDescription--LMrQr";
export var ArtistInfos = "Delta2-module--ArtistInfos--CzfTK";
export var ButtonWrapper = "Delta2-module--ButtonWrapper --60ERf";
export var CardWrapper = "Delta2-module--CardWrapper--WfO+O";
export var CarrouselWrapper2 = "Delta2-module--CarrouselWrapper2--IQLIi";
export var Citations = "Delta2-module--Citations--v6oGv";
export var DescriptionWrapper = "Delta2-module--DescriptionWrapper--6639K";
export var ImageWrapper = "Delta2-module--ImageWrapper--pTXPH";
export var LinkWrapper = "Delta2-module--LinkWrapper--ZmKfE";
export var MobileProtrait = "Delta2-module--MobileProtrait--pEFHz";
export var More = "Delta2-module--More--Ls7Zs";
export var MoreButton = "Delta2-module--MoreButton--lWe4T";
export var NameWrapper = "Delta2-module--NameWrapper--J3RLv";
export var PdpWrapper = "Delta2-module--PdpWrapper--HcI3d";
export var PhotosWrapper = "Delta2-module--PhotosWrapper--Rsink";
export var ProfilWrapper = "Delta2-module--ProfilWrapper--5Of09";
export var TitleWrapper = "Delta2-module--TitleWrapper--kRx7r";
export var Wrapper = "Delta2-module--Wrapper--PgLmK";