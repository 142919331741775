
import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./Delta2.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import Delta2Photo1 from "../../../../res/Photos site/Delta 2/Delta 2.png"
import Delta2Photo2 from "../../../../res/Photos site/Delta 2/delta2.jpg"
import Delta2Photo3 from "../../../../res/Photos site/Photos Oeuvres Disponibles/d2.png"
import Delta2Photo4 from "../../../../res/Photos site/Photos Oeuvres Disponibles/d22.png"
import CarrouselCartel from "../../../../components/CarrouselCartel";
import Pdp from '../../../../res/delta2/portrait.jpg'
import PastPresent1 from "../../../../res/delta2/past-present-1.jpeg"
import PastPresent2 from "../../../../res/delta2/past-present-2.jpg"
import PastPresent3 from "../../../../res/delta2/past-present-3.jpg"
const isBrowser = typeof window !== "undefined";
const infos ={
  path: "Delta2",
  name: "Delta2",
  description:"DELTA 2 a grandi dans le quartier espagnol de Harlem à Manhattan et s’est impliqué dans la peinture de métro à la fin des années 1970. Ses premières observations des autres artistes tels que Lee et Blade l’ont poussé à créer son propre style. Ainsi sa carrière de peintre a culminé au début des années 1980. Au début de cette période, Delta 2  à également fondé l’équipage Kings Arrive. Il connaît un grand succès sur la scène artistique internationale. À partir de 1984, il peint dans des contextes plus conventionnels. Il a participé à une présentation d’art graffiti de Guillaume Gallozo pour le 25ème anniversaire de Valentino à Rome en Italie et a vendu des peintures dans toute l’Europe.",
  pdp: Pdp,
  alt_pdp: "Photo de profil de Delta2.",
  photos: [
    { src: PastPresent1, name: "Delta2" },
    { src: PastPresent2, name: "Delta2" },
    { src: PastPresent3, name: "Delta2" },
  ],
};


const infos2 = {
  photos: [
    { src: Delta2Photo3, name: "Delta 2", artist:"Delta 2\n(Jean Gallard dit) (né en 1965)", title:"Pander, 2018", desc1: "Acrylique, bombe aérosol et marqueur sur toile", desc2 :"Acrylic, spray paint and marker on canvas", desc3: "75 x 60 cm", desc4:" 29 1/4 x 23 1/4 in.", desc7: "- Acquis auprès de l'artiste." },
    { src: Delta2Photo4, name: "Delta 2", artist:"Delta 2\n(Jean Gallard dit) (né en 1965)", title:"Untitled, 2020", desc1: "Acrylique sur toile", desc2:"Acrylic on canvas", desc3:"40 x 50 cm", desc4: "15 3/4 x 19 3/4 in.", desc5:"Signé au dos.", desc6:"Signed at the back", desc7:"- Collection particulière.\n- Acquis directement auprès de l'artiste"},
  ],
};

const Delta2 = () => {
    return (
        <div className={Wrapper}>
        <div className={CardWrapper}>
          <div className={ProfilWrapper} id="f">
            <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
          </div>
          <div>
            <h1 className={NameWrapper}>Delta 2</h1>
            <h3 className={NameWrapper} style={{paddingTop: '0'}}>Né en 1965</h3>
            <p className={DescriptionWrapper}>{infos.description}</p>
          </div>
        </div>
        <div className={PhotosWrapper}>
          <Title title={"Past or Present"} />
          <div className={CarrouselWrapper2}>
          </div>
        </div>
      </div>
      );
};

export default Delta2;